import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className={'py-8 lg:py-16 mt-auto'}>
      <div className={'mx-auto max-w-screen-xl w-full px-6 relative'}>
        <div className={'md:flex md:justify-between'}>
          <div className={'mt-2'}>
            &copy; 2016 <Link to="/">Gillian Morrow</Link>
          </div>
          <div className={'mt-2'}>
            Website by <a rel="noopener noreferrer" href="https://palebluedot.tv" target="_blank">Pale Blue Dot</a>
          </div>
        </div>
      </div>
    </footer>
  )
}
