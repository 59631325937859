import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
const NotFoundPage = () => {
  return (
    <Layout>
      <main className={'mx-auto max-w-screen-xl w-full px-6 py-8 lg:py-16 relative'}>
          <h1 className={'text-6xl font-bold my-24 max-w-screen-lg'}>Page not found</h1>
          <p className={'text-3xl mb-16 max-w-screen-md'}>Sorry 😔, we couldn’t find what you were looking for.</p>
          <p><Link to="/" className={'border-b-2 border-black hover:border-transparent transition py-1'}>Go home</Link>.</p>

      </main>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
