import React from "react"
import { Link } from "gatsby"

export default function Header({ setIsOpen }) {
  return (
    <header className={'z-10 relative'}>
      <nav className={'mx-auto max-w-screen-xl w-full px-6 relative'}>
        <ul className={'flex justify-between gap-8 items-center py-8'}>
          <li className={'mr-auto'}>
            <Link to="/" className={'font-bold text-2xl'}>
              Gillian Morrow.
            </Link>
          </li>
          <li className={'hidden lg:block'}>
            <Link to="/about/" className={'border-b-2 border-transparent hover:border-black transition py-1'} activeClassName={'!border-black text-black'}>about me</Link>
          </li>
          <li className={'hidden lg:block'}>
            <Link to="/portfolio/" className={'border-b-2 border-transparent transition py-1'}  activeClassName={'!border-black text-black'}>portfolio</Link>
          </li>
          <li className={'hidden lg:block'}>
            <Link to="/contact/" className={'border-b-2 border-transparent transition py-1'}  activeClassName={'!border-black text-black'}>contact</Link>
          </li>
          <li className={'hidden lg:block'}>
            <Link to="/blog/" className={'border-b-2 border-transparent transition py-1'}  activeClassName={'!border-black text-black'}>blog</Link>
          </li>
          <li className={'lg:hidden'}>
            <button onClick={() => setIsOpen(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={'w-6 h-6'}>
                <title>navigation</title>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
             </button>
          </li>
        </ul>

      </nav>

    </header>
  )
}
