import React from "react"
import { Link } from "gatsby"
import Footer from "./footer"
import Header from "./header"
import Drawer from "./Drawer"
export default function Layout({ children }) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="text-black flex flex-col h-full">
      <Header setIsOpen={setIsOpen}  />
      <div id="content">
        {children}
      </div>
      <Footer />
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <ul>
          <li>
            <Link to="/" className={'block p-2'} activeClassName={'text-black'}>home</Link>
          </li>
          <li>
            <Link to="/about/" className={'block p-2'} activeClassName={'text-black'}>about me</Link>
          </li>
          <li>
            <Link to="/portfolio/" className={'block p-2'}  activeClassName={'text-black'}>portfolio</Link>
          </li>
          <li>
            <Link to="/contact/" className={'block p-2'}  activeClassName={'text-black'}>contact</Link>
          </li>
          <li>
            <Link to="/blog/" className={'block p-2'}  activeClassName={'text-black'}>blog</Link>
          </li>
        </ul>
      </Drawer>
    </div>
  )
}
